<template>
  <el-dialog
      :model-value="modelValue"
      title="联系我们"
      width="520"
      class="shop-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeDia"
      align-center
  >
    <div class="content">
      <concat-pic class="pic"></concat-pic>
      <div class="sub-desc">{{ value }}</div>
    </div>
  </el-dialog>
</template>

<script setup>
import {getCurrentInstance, toRefs} from "vue";
import ConcatPic from "@views/shop/components/concatPic.vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  value: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:modelValue'])
const {modelValue, value} = toRefs(props)
const {proxy} = getCurrentInstance();
const closeDia = (done) => {
  emit('update:modelValue', !modelValue.value)
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "concatDIa",
});
</script>

<style lang="scss" scoped>
.shop-dialog {
  .content {
    text-align: center;
    padding-bottom: 30px;

    .pic {
      margin: 0 auto;
    }

    .sub-desc {
      margin-top: 16px;
      color: $shopFsColor;
      font-size: 14px;
    }
  }
}
</style>
