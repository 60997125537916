<template>
  <div class="concat-pic">
    <img :src="url" alt="位元灵感" class="pic"/>
  </div>
</template>

<script setup>
import {getCurrentInstance} from "vue";

const url = require('@img/shop/index/wechatPic.jpg');
const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "concatPic"
});
</script>

<style lang="scss" scoped>
.concat-pic {
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding: 12px;
  width: max-content;
  .pic {
    width: 150px;
    height: 150px;
  }
}
</style>
